<template>
  <b-modal id="modal-files" hide-footer scrollable>
    <template #modal-header>
      <h4>
        {{ $t("files.new") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              class="col-md-12"
              :label="$t('categories.category')"
              label-for="category"
            >
              <ValidationProvider
                :name="$t('categories.category')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="data.category"
                  :placeholder="$t('categories.category')"
                  :options="getCategories"
                  label="name"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}">
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              class="col-md-12"
              :label="$t('files.name')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('files.name')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.name"
                  type="text"
                  :placeholder="$t('files.name')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              class="col-md-12"
              :label="$t('files.file')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('files.file')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-file
                  accept="application/pdf"
                  :placeholder="$t('files.select')"
                  v-model="data.file"
                  ref="file-input"
                  class="mb-2"
                >
                </b-form-file>

                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="filesLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="filesLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            @click="$bvModal.hide('modal-files')"
            class="float-right mr-1"
            variant="outline-danger"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "modalForm",
  mounted() {
    core.index();
  },
  data() {
    return {
      typePDF: "application/pdf",
      data: {
        name: "",
        category: null,
        file: null,
      },
    };
  },
  methods: {
    ...mapActions({
      uploadFile: "uploadFile",
    }),
    async show(item) {
      this.$bvModal.show("modal-files");
      this.reserForm();
    },
    async onSubmit() {
      const { file, category, name } = this.data;
      if (file.type !== this.typePDF) {
        return core.showSnackbar(
          "error",
          "El formato del archivo debe ser .pdf"
        );
      }
      let payload = {
        name,
        category_id: category.id,
        file,
      };
      const resp = await this.uploadFile(payload);
      this.$bvModal.hide("modal-files");
      this.$emit("refresh");
      core.showSnackbar("success", this.$t("app.success-create"));
    },
    reserForm() {
      this.data = {
        category: null,
        file: null,
      };
      setTimeout(() => {
        this.$refs["file-input"].reset();
      }, 1000);
    },
  },
  computed: {
    ...mapGetters({
      filesLoading: "filesLoading",
      getCategories: "getCategories",
    }),
  },
};
</script>
